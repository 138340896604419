<template>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          {{ $t("editdiscount") }}
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <div class="row d-flex justify-content-center mb-4">
          <div class="col-sm-5">
            <label for="selectMainProduct" class="form-label required">{{
              $t("discountscode")
            }}</label>
            <input disabled v-model="form.code" class="form-control mb-4" />
          </div>
          <div class="col-sm-5">
            <label for="selectMainProduct" class="form-label required">{{
              $t("discountsname")
            }}</label>
            <input disabled v-model="form.name" class="form-control mb-4" />
          </div>

          <div class="col-sm-8">
            <label class="form-label col-sm-10">priority</label>
            <input
              v-model="form.priority"
              class="form-control mb-4"
              placeholder="3/4"
              @keypress.enter="searchfilter(form.priority)"
            />
            <!-- <select class="form-select" v-model="form.priority" required>
              <option v-for="item in 5" :key="item">
                {{ item }}
              </option>
            </select> -->
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
          <div class="col-sm-2">
            <button
              type="button"
              class="btn btn-sm btn-light btn-success mt-9"
              style="font-size: 14px; font-weight: bold"
              @click="searchfilter(form.priority)"
            >
              filter
            </button>
          </div>
        </div>
        <hr class="pt-0 mt-0" style="color: LightGrey" />
        <section v-if="form.priority == 3" class="mt-4">
          <div class="row text-end">
            <div class="col-sm-12">
              <button
                type="button"
                class="btn btn-sm btn-light btn-success"
                style="font-size: 14px; font-weight: bold"
                @click="addItem()"
              >
                + เพิ่ม
              </button>
              <AddItem
                :dialogAddItem="dialogAddItem"
                :priority="form.priority"
                :VanId="form.fromVanId"
                @addProduct="addProduct"
                @closeDialogAddItem="closeDialogAddItem"
              />
            </div>
          </div>
          <div class="table-responsive" style="padding-top: 12px">
            <table
              class="table table-hover table-rounded table-striped border gy-7 gs-7"
            >
              <thead>
                <tr
                  style="width: 100%"
                  class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
                >
                  <th>#</th>
                  <th>Customer</th>
                  <th>Item Group</th>
                  <th width="15%" style="text-align: center">DsT</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="dataItemsShow3.length < 1">
                  <td class="text-center" colspan="6">ไม่มีข้อมูล</td>
                </tr>
                <tr v-for="(item, index) in dataItemsShow3" :key="index">
                  <td v-if="item.priority == 3"></td>
                  <td v-if="item.priority == 3">
                    {{ displayCustomer(item.customerId) }}
                  </td>
                  <td v-if="item.priority == 3">
                    {{ item.code || item.itemGroupHeader.code }} |
                    {{ item.name || item.itemGroupHeader.name }}
                  </td>
                  <td
                    style="text-align: center"
                    v-if="item.discountType == 'bath' && item.priority == 3"
                  >
                    {{ item.discountAmt }} บาท
                  </td>
                  <td
                    style="text-align: center"
                    v-if="item.discountType == 'percent' && item.priority == 3"
                  >
                    {{ item.discountAmt }} %
                  </td>
                  <td v-if="item.priority == 3">
                    <div class="row">
                      <div class="col-sm-6 d-flex justify-content-md-end">
                        <button
                          type="button"
                          class="btn btn-link btn-color-muted btn-active-color-success p-0"
                          @click="goToEdit(item)"
                        >
                          <i class="bi bi-pencil-square"></i>
                        </button>
                      </div>
                      <div class="col-sm-6 d-flex">
                        <button
                          type="button"
                          class="btn btn-link btn-color-muted btn-active-color-danger p-0"
                          @click="goToDelete3(item, index)"
                        >
                          <i class="bi bi-trash3-fill"></i>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        <!-- กั้น -->
        <section v-if="form.priority == 4" class="mt-4">
          <div class="row text-end">
            <div class="col-sm-12">
              <button
                type="button"
                class="btn btn-sm btn-light btn-success"
                style="font-size: 14px; font-weight: bold"
                @click="addItem()"
              >
                + เพิ่ม
              </button>
              <AddItem
                :dialogAddItem="dialogAddItem"
                :priority="form.priority"
                :VanId="form.fromVanId"
                @addProduct="addProduct"
                @closeDialogAddItem="closeDialogAddItem"
              />
            </div>
          </div>
          <div class="table-responsive" style="padding-top: 12px">
            <table
              class="table table-hover table-rounded table-striped border gy-7 gs-7"
            >
              <thead>
                <tr
                  style="width: 100%"
                  class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
                >
                  <th>#</th>
                  <th>District</th>
                  <th>Item Group</th>
                  <th width="15%" style="text-align: center">DsT</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="dataItemsShow4.length < 1">
                  <td class="text-center" colspan="6">ไม่มีข้อมูล</td>
                </tr>
                <tr v-for="(item, index) in dataItemsShow4" :key="index">
                  <td v-if="item.priority == 4"></td>
                  <td v-if="item.priority == 4">
                    <!-- {{ item.code || item.itemGroupHeader.code }} -->
                    {{ displayDistrict(item.districtDiscountId) }}
                  </td>
                  <td v-if="item.priority == 4">
                    {{
                      item.name || item.itemGroupHeader
                        ? item.itemGroupHeader.name
                        : ""
                    }}
                  </td>
                  <td
                    style="text-align: center"
                    v-if="item.discountType == 'bath' && item.priority == 4"
                  >
                    {{ item.discountAmt }} บาท
                  </td>
                  <td
                    style="text-align: center"
                    v-if="item.discountType == 'percent' && item.priority == 4"
                  >
                    {{ item.discountAmt }} %
                  </td>
                  <td v-if="item.priority == 4">
                    <div class="row">
                      <div class="col-sm-6 d-flex justify-content-md-end">
                        <button
                          type="button"
                          class="btn btn-link btn-color-muted btn-active-color-success p-0"
                          @click="goToEdit(item)"
                        >
                          <i class="bi bi-pencil-square"></i>
                        </button>
                      </div>
                      <div class="col-sm-6 d-flex">
                        <button
                          type="button"
                          class="btn btn-link btn-color-muted btn-active-color-danger p-0"
                          @click="goToDelete4(item, index)"
                        >
                          <i class="bi bi-trash3-fill"></i>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar row justify-content-center">
          <div class="col-sm-10">
            <button
              type="button"
              class="btn btn-sm btn-light btn-primary ms-3 me-2"
              style="font-size: 14px; font-weight: bold"
              @click="submit()"
              :disabled="loading"
            >
              <div
                v-if="loading"
                class="spinner-grow spinner-grow-sm text-light"
                role="status"
              ></div>
              {{ $t("save") }}
            </button>
            <button
              type="button"
              class="btn btn-sm btn-light"
              style="font-size: 14px; font-weight: bold"
              @click="cancel"
            >
              {{ $t("cancel") }}
            </button>
          </div>
        </div>
      </footer>
    </form>
    <EditItem
      :dialogEditItem="dialogEditItem"
      :itemEdit="itemEdit"
      :priority="form.priority"
      @editProduct="editProduct"
      @closeDialogEditItem="closeDialogEditItem"
    />
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";
import AddItem from "../discount/dialogAddItem.vue";
import EditItem from "../discount/dialogEditItem.vue";
import promotionAPI from "@/api/promotion/";
import customerAPI from "@/api/customer/";

export default {
  components: {
    AddItem,
    EditItem,
  },
  setup() {
    document.title = "J&N | เพิ่มคลังสินค้า";
  },
  data() {
    return {
      dialogAddItem: false,
      dialogEditItem: false,
      loading: false,
      isSubmit: false,
      // parseInt(localStorage.getItem("companyId")),
      form: {
        companyId: parseInt(localStorage.getItem("companyId")),
        code: "",
        name: " ",
        priority: null,
      },
      filter: "",

      dataItemsShow3: [],
      dataItemsShow4: [],
      customers: [],
      districts: [],
      columns: [
        { title: "#", dataIndex: "#" },
        { title: "code", dataIndex: "code" },
        { title: "name", dataIndex: "name" },
      ],
    };
  },

  created() {
    this.getOne();
    this.getAllCustomer();
    this.getAllDistrict();
  },
  methods: {
    displayCustomer(val) {
      let result;
      this.customers.filter((element) => {
        if (val == element.id) {
          result = element.code + " | " + element.name;
        }
      });

      return result;
    },
    displayDistrict(val) {
      let result;
      this.districts.filter((element) => {
        if (val == element.id) {
          result = element.name;
        }
      });

      return result;
    },
    async getAllCustomer() {
      let responseData = [];
      try {
        responseData = await customerAPI.customer.getAll(
          localStorage.getItem("branchId"),
          localStorage.getItem("companyId")
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.customers = responseData.data;
      }
    },
    async getAllDistrict() {
      let responseData = [];
      try {
        responseData = await promotionAPI.district.getAll(
          localStorage.getItem("companyId")
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.districts = responseData.data;
      }
    },
    searchfilter(val) {
      this.form.filter = val;

      this.getOne(val);
    },
    async getOne(priority) {
      let responseData = [];
      try {
        responseData = await promotionAPI.discount.getOne(this.$route.query.id);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.form.id = responseData.data.id;
        this.form.code = responseData.data.code;
        this.form.name = responseData.data.name;
        let arr = responseData.data.discountModelItems;
        this.dataItemsShow3 = [];
        this.dataItemsShow4 = [];
        arr.filter((element) => {
          if (element.priority == 3) {
            this.dataItemsShow3.push(element);
          } else if (element.priority == 4) {
            this.dataItemsShow4.push(element);
          }
        });
        // this.tableItems = this.dataItemsShow;
        // this.allData = this.dataItemsShow;
      }
    },
    async submit() {
      Swal.fire({
        icon: "question",
        title: `${this.$t("want2submit")} ${this.$t("ornot")} ?`,
        confirmButtonText: `${this.$t("ok")} `,
        cancelButtonText: `${this.$t("cancel")} `,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.validateForm();
          await this.checkFormIsEmpty();

          if (this.isValid) {
            let createResponse = [];

            this.isSubmit = true;
            this.loading = true;
            let arr = [];
            // arr.concat(this.dataItemsShow3, this.dataItemsShow4);
            arr = this.dataItemsShow3.concat(this.dataItemsShow4);

            this.form = {
              ...this.form,
              discountModelItems: arr,
              discountModelId: this.$route.query.id,
              companyId: parseInt(localStorage.getItem("companyId")),
            };

            createResponse = await promotionAPI.discount.update(
              this.$route.query.id,
              this.form
            );
            if (createResponse.response_status === "SUCCESS") {
              // await this.submitContacts(createResponse.data.id);
              Swal.fire({
                icon: "success",
                title: `${this.$t("editsuccess")} `,
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.$router.push({
                  path: "/promotion/discount/index",
                  query: {},
                });
              });
              this.loading = false;
            } else {
              Swal.fire({
                icon: "error",
                title: `${createResponse.message}`,
                showConfirmButton: false,
                timer: 1500,
              });
              this.loading = false;
            }
          }
        }
      });
    },

    checkFormIsEmpty() {
      !this.form.code
        ? (this.isValid = false)
        : !this.form.name
        ? (this.isValid = false)
        : (this.isValid = true);
    },

    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            if (!form.checkValidity()) {
              event.preventDefault();
              event.stopPropagation();
            }

            form.classList.add("was-validated");
          },
          false
        );
      });
    },

    async goToDelete3(item, index) {
      this.dataItemsShow3.splice(index, 1);

      // this.tableItems.splice(index, 1);

      // Swal.fire({
      //   icon: "question",
      //   title: `${this.$t("want2delete")} ${item.itemGroupHeader.name} ${this.$t("ornot")} ?`,
      //   confirmButtonText: `${this.$t("ok")} `,
      //   cancelButtonText: `${this.$t("cancel")} `,
      //   showCancelButton: true,
      // }).then(async (result) => {
      //   if (result.isConfirmed) {
      //     this.confirmDelete(item, index);
      //   }
      // });
    },
    async goToDelete4(item, index) {
      this.dataItemsShow4.splice(index, 1);

      // this.tableItems.splice(index, 1);
      // Swal.fire({
      //   icon: "question",
      //   title: `${this.$t("want2delete")} ${item.itemGroupHeader.name} ${this.$t("ornot")} ?`,
      //   confirmButtonText: `${this.$t("ok")} `,
      //   cancelButtonText: `${this.$t("cancel")} `,
      //   showCancelButton: true,
      // }).then(async (result) => {
      //   if (result.isConfirmed) {
      //     this.confirmDelete(item, index);
      //   }
      // });
    },
    async confirmDelete(item, index) {
      // let deleteResponse = [];
      // deleteResponse = await whApi.warehouse.delete(item.id);
      // if (deleteResponse.response_status === "SUCCESS") {
      //   Swal.fire({
      //     icon: "success",
      //     title: `${this.$t("deletesuccessfull")} `,
      //     showConfirmButton: false,
      //     timer: 1500,
      //   }).then(() => {
      //     this.dataItemsShow.splice(index, 1);
      //     this.tableItems.splice(index, 1);
      //     this.getOne(1);
      //   });
      // }
    },
    addItem() {
      this.dialogAddItem = true;
    },
    closeDialogAddItem() {
      this.dialogAddItem = false;
    },
    goToEdit(item) {
      this.itemEdit = item;
      // this.$router.push(`/promotion/discount/edit?id=${id}`);
      this.dialogEditItem = true;
    },
    closeDialogEditItem() {
      this.dialogEditItem = false;
    },

    cancel() {
      this.$router.push({
        path: "/promotion/discount/index",
        query: {},
      });
    },
    addProduct(item) {
      console.log("addProduct", item);
      if (item.priority == "3") {
        this.dataItemsShow3.push(item);
      } else {
        this.dataItemsShow4.push(item);
      }
    },
  },
};
</script>



